"use client";

import { useTranslations } from "next-intl";

import ConfirmModal from "~/components/ConfirmModal";
import { useModalContext } from "~/app/[locale]/(components)/ModalProvider";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { useRouter } from "~/navigation";

function CashRegisterUpsellModalWidget() {
  const t = useTranslations("widgets.cash-register-upsell-modal");
  const router = useRouter();

  const { modalOpen, closeModal } = useModalContext("upsell-cash-register");

  const handleConfirm = () => {
    closeModal();

    trackAmplitudeEvent("pdv_all_see_plans_click", {
      from: "friction_cash_register",
    });

    router.push("/account/plans");
  };

  return (
    <ConfirmModal
      open={modalOpen}
      title={t("title")}
      description={t("description")}
      dismissButtonText={t("dismissButton")}
      confirmButtonText={t("confirmButton")}
      onDismiss={closeModal}
      onConfirm={handleConfirm}
    />
  );
}

export default CashRegisterUpsellModalWidget;
